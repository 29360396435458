<style lang="scss">
 @media (max-width: 6000px) and (min-width: 5975px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 14% !important;
    }
  }

  @media (max-width: 5974px) and (min-width: 5875px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 14.5% !important;
    }
  }

  @media (max-width: 5874px) and (min-width: 5775px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 15% !important;
    }
  }

  @media (max-width: 5774px) and (min-width: 5675px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 15.5% !important;
    }
  }

  @media (max-width: 5674px) and (min-width: 5575px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 16% !important;
    }
  }

  @media (max-width: 5574px) and (min-width: 5475px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 16.5% !important;
    }
  }

  @media (max-width: 5474px) and (min-width: 5375px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 17% !important;
    }
  }

  @media (max-width: 5374px) and (min-width: 5275px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 17.5% !important;
    }
  }

  @media (max-width: 5274px) and (min-width: 5175px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 18% !important;
    }
  }

  @media (max-width: 5174px) and (min-width: 5075px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 18.5% !important;
    }
  }

  @media (max-width: 5074px) and (min-width: 4975px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 19% !important;
    }
  }

  @media (max-width: 4974px) and (min-width: 4875px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 19.5% !important;
    }
  }

  @media (max-width: 4874px) and (min-width: 4775px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 20% !important;
    }
  }

  @media (max-width: 4774px) and (min-width: 4675px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 20.5% !important;
    }
  }

  @media (max-width: 4674px) and (min-width: 4575px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 21% !important;
    }
  }

  @media (max-width: 4574px) and (min-width: 4475px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 21.5% !important;
    }
  }

  @media (max-width: 4474px) and (min-width: 4375px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 22% !important;
    }
  }

  @media (max-width: 4374px) and (min-width: 4275px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 22.5% !important;
    }
  }

  @media (max-width: 4274px) and (min-width: 4175px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 23% !important;
    }
  }

  @media (max-width: 4174px) and (min-width: 4075px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 23.5% !important;
    }
  }

  @media (max-width: 4074px) and (min-width: 3975px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 24% !important;
    }
  }

  @media (max-width: 3974px) and (min-width: 3875px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 24.5% !important;
    }
  }

  @media (max-width: 3874px) and (min-width: 3775px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 25% !important;
    }
  }

  @media (max-width: 3774px) and (min-width: 3675px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 25.5% !important;
    }
  }

  @media (max-width: 3674px) and (min-width: 3575px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 26% !important;
    }
  }

  @media (max-width: 3574px) and (min-width: 3475px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 26.5% !important;
    }
  }

  @media (max-width: 3474px) and (min-width: 3375px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 27% !important;
    }
  }

  @media (max-width: 3374px) and (min-width: 3275px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 27.5% !important;
    }
  }

  @media (max-width: 3274px) and (min-width: 3175px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 28% !important;
    }
  }

  @media (max-width: 3174px) and (min-width: 3075px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 28.5% !important;
    }
  }

  @media (max-width: 3074px) and (min-width: 2975px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 29% !important;
    }
  }

  @media (max-width: 2974px) and (min-width: 2875px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 29.5% !important;
    }
  }

  @media (max-width: 2874px) and (min-width: 2775px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 30% !important;
    }
  }

  @media (max-width: 2774px) and (min-width: 2675px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 30.5% !important;
    }
  }

  @media (max-width: 2674px) and (min-width: 2575px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 31% !important;
    }
  }

  @media (max-width: 2574px) and (min-width: 2475px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 31.5% !important;
    }
  }

  @media (max-width: 2474px) and (min-width: 2375px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 32% !important;
    }
  }

  @media (max-width: 2374px) and (min-width: 2275px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 32.5% !important;
    }
  }
  @media (max-width: 2274px) and (min-width: 2175px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 33% !important;
    }
  }
  @media (max-width: 2174px) and (min-width: 2075px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 33.5% !important;
    }
  }
  @media (max-width: 2074px) and (min-width: 1975px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 34% !important;
    }
  }

  @media (max-width: 1974px) and (min-width: 1901px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 36% !important;
    }
  }
  @media (max-width: 1900px) and (min-width: 1875px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 38% !important;
    }
  }
  @media (max-width: 1874px) and (min-width: 1801px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 40% !important;
    }
  }
  @media (max-width: 1800px) and (min-width: 1775px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 42% !important;
    }
  }
  @media (max-width: 1774px) and (min-width: 1701px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 44% !important;
    }
  }
  @media (max-width: 1700px) and (min-width: 1675px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 46% !important;
    }
  }
  @media (max-width: 1674px) and (min-width: 1601px) {
    .login-spore-container {
      flex: 0 0 50% !important;
      max-width: 48% !important;
    }
  }
  @media (min-width: 992px) {
    .login-spore-left {
      margin-left: 4rem !important;
    }
    .login-image-spore-responsive {
      display: none !important;
    }
  }
  @media (max-width: 991px) {
    .login-spore-bottom {
      margin-bottom: 10rem !important;
    }
    .login-image-spore-responsive {
      min-height: auto;
      position: absolute;
      right: calc(31% - 150px);
      bottom: calc(-10% - 150px);
    }
  }
  @media (max-width: 575px) and (max-height: 950px)  {
    .login-spore-bottom {
      margin-bottom: 14rem !important;
    }
    .login-image-spore-responsive {
      min-height: auto;
      position: absolute;
      right: calc(31% - 150px);
      bottom: calc(12% - 150px);
      height: 37%;
    }
  }
  @media (max-width: 575px) and (max-height: 850px)  {
    .login-spore-bottom {
      margin-bottom: 14rem !important;
    }
    .login-image-spore-responsive {
      min-height: auto;
      position: absolute;
      right: calc(35% - 150px);
      bottom: calc(13% - 150px);
      height: 40%;
    }
  }
  @media (max-width: 575px) and (max-height: 750px)  {
    .login-spore-bottom {
      margin-bottom: 14rem !important;
    }
    .login-image-spore-responsive {
      min-height: auto;
      position: absolute;
      right: calc(31% - 150px);
      bottom: calc(5% - 150px);
      height: 52%;
    }
  }
  @media (max-width: 575px) and (max-height: 650px)  {
    .login-spore-bottom {
      margin-bottom: 14rem !important;
    }
    .login-image-spore-responsive {
      min-height: auto;
      position: absolute;
      right: calc(35% - 150px);
      bottom: calc(9% - 150px);
      height: 49%;
    }
  }
  @media (min-width: 1200px) {
    .login-image-spore {
      min-height: auto;
      min-width: 100%;
      position: absolute;
      top: -1px;
      right: -1px;
    }
  }
  @media (max-width: 1199px) and (min-width: 992px) {
    .login-image-spore {
      min-height: auto;
      position: absolute;
      top: -50px;
      right: -100px;
    }
  }
  @media (max-width: 1377px) and (min-width: 1263px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 30px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  @media (max-width: 1262px) and (min-width: 1055px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 28px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  @media (max-width: 1054px) and (min-width: 992px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 25px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  @media (max-width: 991px) and (min-width: 872px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 28px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  @media (max-width: 871px) and (min-width: 388px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 28px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  @media (max-width: 387px) and (min-width: 327px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 27px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  @media (max-width: 326px) and (min-width: 308px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 25px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  @media (max-width: 307px) and (min-width: 289px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 23px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  @media (max-width: 288px) and (min-width: 270px) {
    .login-spore-title {
      color: #ffffff !important;
      font: normal normal bold 21px/34px Merriweather Sans !important;
      font-weight: bold;
    }
  }
  .login-image-spore-container {
    position: relative;
  }
  .login-image-spore {
    min-height: auto;
    position: absolute;
  }
  .images-spore-container {
    background: transparent linear-gradient(241deg, #14054E 0%, #0B022B 100%) 0% 0% no-repeat padding-box !important;
    border-radius: 0px 60px 60px 0px !important;
    opacity: 1;
  }
  .login-spore-logo-container {
    margin-bottom: 10px;
    text-align: left;
  }
  .login-spore-title {
    color: #ffffff !important;
    font: normal normal bold 34px/34px Merriweather Sans;
    font-weight: bold;
  }
  .login-spore-label1 {
    color: #ffffff !important;
    font: normal normal normal 18px/20px Merriweather Sans;
    font-weight: normal;
  }
  .login-spore-button {
    background: #ffffff !important;
    border-color: #ffffff !important;
    border-radius: 4px;
    opacity: 1;
    color: #7577C8 !important;
    font-weight: bold !important;
    position: relative;
    z-index: 10;
  }
  .auth-inner-spore {
    overflow-y: auto;
    height: calc(var(--vh, 1vh)*100);
    background: transparent linear-gradient(490deg, #14054E 0%, #0B022B 100%) 0% 0% no-repeat padding-box !important;
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
  .input-group-prepend > .input-group-text {
    background-color: transparent;
    border-right: none !important;
    border-color: #7577C8 !important;
    color:#ffffff;
    font-weight: bold;
  }
  .input-group-prepend > .input-group-text {
    background-color: transparent;
    border-right: none !important;
    border-color: #7577C8 !important;
    color:#ffffff;
    font-weight: bold;
  }
  .input-group-prepend + input.form-control {
    border-left: none !important;
    border-color: #7577C8 !important;
    color:#ffffff;
  }
  .input-group-prepend + input.form-control:focus {
    outline: none;
    border-color: #7577C8 !important;
    color:#ffffff;
  }
  .input-group-append > .input-group-text {
    background-color: transparent;
    border-color: #7577C8 !important;
    color:#ffffff;
    font-weight: bold;
  }
  .input-group-append > .input-group-text {
    background-color: transparent;
   border-color: #7577C8 !important;
   color:#ffffff;
   font-weight: bold;
  }
  .input-group-append + input.form-control {
    border-left: none !important;
    border-color: #7577C8 !important;
    color:#ffffff;
  }
  .input-group-append + input.form-control:focus {
    outline: none;
    border-color: #7577C8 !important;
    color:#ffffff;
  }

</style>

<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner auth-inner-spore m-0">
        <b-alert
          variant="danger"
          :show="$store.state.sessionExpired.isExpired"
        >
          <div class="alert-body" v-if="this.expired">
            <span>{{$t('Messages.ExpiredSession')}}</span>
          </div>
        </b-alert>

          <div class="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 d-flex align-items-center auth-bg px-2 p-lg-5 mb-3" style="justify-content: center;">
              <div class="col-xl-6 col-lg-7 col-md-5 col-sm-12 col-xs12 login-spore-left login-spore-bottom login-spore-container">
                <div class="login-tabs-container d-flex justify-content-center">
                  <div class="spore-logo-container">
                    <b-img :src="require('@/assets/images/logo/logoSpore.png')" class="spore-login-logo"></b-img>
                  </div>
                </div>
                <div class="d-flex flex-column align-items-center">
                  <b-card-title title-tag="h4" class="font-weight-bold mt-5 mb-1 login-spore-title">
                    Bienvenido a Spore
                  </b-card-title>
                  <b-card-text class="login-spore-label1">
                    {{$t("InputDataMessage")}}
                  </b-card-text>
                </div>
                <validation-observer ref="loginValidation">
                  <b-form class="auth-login-form mt-2" @submit.prevent>
                    <!-- email -->
                    <validation-provider #default="{ errors }" name="email" rules="required|email" class="">
                      <b-input-group class="input-group-merge mb-1">
                        <b-input-group-prepend is-text>
                          <feather-icon icon="UserIcon" />
                        </b-input-group-prepend>
                        <b-form-input style="background-color: transparent; border-color: #7577C8; color:#ffffff; font-weight: bold;" id="login-email" v-model="userEmail" :state="errors.length > 0 ? false:null" name="login-email" :placeholder="$t('Email')" v-on:keyup.13="validationForm()"/>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <!-- password -->
                    <validation-provider #default="{ errors }" name="password" rules="required">
                        <b-input-group class="input-group-merge mb-1">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input style="background-color: transparent;border-color: #7577C8; color:#ffffff; font-weight: bold;" id="login-password" v-model="password" :state="errors.length > 0 ? false:null" class="form-control-merge" :type="passwordFieldType" name="login-password" :placeholder="$t('Password')"/>
                          <b-input-group-append is-text>
                            <feather-icon class="cursor-pointer" :icon="passwordToggleIcon" @click="togglePasswordVisibility"/>
                          </b-input-group-append>
                        </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                     <!-- submit buttons -->
                    <b-button class="login-spore-button" type="submit" variant="primarySpore" block @click="validationForm">
                      {{$t("Enter")}}
                    </b-button>
                  </b-form>
                </validation-observer>
              </div>
                <b-img :src="require('@/assets/images/pages/login/background_responsive.png')" class="login-image-spore-responsive"></b-img>
          </div>

          <b-col cols="5" xl="5" lg="5" md="6" class="d-none d-lg-flex align-items-center">
            <b-img :src="require('@/assets/images/pages/login/spore_lines_1.png')" class="login-image-spore"></b-img>
          </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import request from '@/libs/request/index'
import { parseJwt, setToken, setSessionStorage } from '@/libs/session/index'
import { app } from '../../../main'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BAlert
  },
  mixins: [togglePasswordVisibility],
  props: {
    urlLogin: String,
    redirectPath: String,
    img: String
  },
  data () {
    return {
      password: '',
      userEmail: '',
      required,
      email,
      expired: false
    }
  },
  computed: {
    passwordToggleIcon () {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    }
  },
  methods: {
    validationForm () {
      this.$refs.loginValidation.validate().then(success => {
        if (success) {
          this.doLogin()
        }
      })
    },
    async getDivisions () {
      const params = {
        url: '/divisions/list/all',
        method: 'GET'
      }
      try {
        const { data } = await request(params)
        return data.data.map((division) => {
          return {
            label: division.name,
            title: division.name,
            value: division.uuid
          }
        })
      } catch (e) {
        console.log(e)
        return []
      }
    },
    async doLogin () {
      this.$store.commit('loading/SHOW')
      const params = {
        url: `${this.urlLogin}/ow`,
        method: 'POST',
        validate: false,
        customMessages: true,
        params: {
          email: this.userEmail,
          password: this.password
        }
      }
      await request(params).then(async response => {
        setSessionStorage('email', btoa(this.userEmail))
        setSessionStorage('pass', btoa(this.password))
        setToken(response.data.data)
        const j = parseJwt(response.data.data)
        setSessionStorage('userRole', j.u.type || 'MO')
        const division = j.u.division || { uuid: '148f4686-4576-407c-9e79-2f57d16445f5', name: 'Centro sur' } // Este uuid de division es default para evitar errores en el menu. Quitar cuando se tenga el uuid de division del usuario.
        let divisionList = []
        if (['A'].includes(j.u.type)) {
          divisionList = await this.getDivisions()
        } else {
          divisionList.push({
            label: division.name,
            title: division.name,
            value: division.uuid
          })
        }
        this.$store.commit('session/UPDATE_USER_INFO', {
          displayName: j.u.name || 'General User',
          uuid: j.u.userUuid || 0,
          userRole: j.u.type || 'MP',
          token: response.data.data,
          avatar: j.u.avatar || '',
          hasUpdatedPassword: j.u.hasUpdatedPassword ?? true,
          division,
          divisionList
        })
        this.$store.commit('sessionExpired/SETVALID')
        this.$router.push(this.redirectPath)
      }).finally(() => {
        this.$store.commit('loading/CLOSE')
        this.password = ''
      })
    },
    getImg () {
      const images = require.context('@/assets/images/pages/login/', false, /\.png$/)
      if (app.$route.path === '/login') {
        this.expired = true
      }
      return images(`./${this.img}`)
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/spore-components/login.scss';
</style>
